class MaskUtils {
    public formatCPF = (cpf: string) => {
        // Remove non-numeric characters
        cpf = cpf.replace(/\D/g, '');
    
        // Format based on length
        if (cpf.length <= 3) {
            return cpf;
        }
        if (cpf.length <= 6) {
            return `${cpf.slice(0, 3)}.${cpf.slice(3)}`;
        }
        if (cpf.length <= 9) {
            return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6)}`;
        }
        return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6, 9)}-${cpf.slice(9, 11)}`;
    };

    public formatCNPJandCPF = (cpf: string) => {
        cpf = cpf.replace(/\D/g, '');
    
        // Format based on length
        if (cpf.length <= 11) {
            return this.formatCPF(cpf);
        }
        
        if (cpf.length <= 12) {
            return `${cpf.slice(0, 2)}.${cpf.slice(2, 5)}.${cpf.slice(5, 8)}/${cpf.slice(8)}`; // xx.xxx.xxx/xxxx
        }
       
        return `${cpf.slice(0, 2)}.${cpf.slice(2, 5)}.${cpf.slice(5, 8)}/${cpf.slice(8, 12)}-${cpf.slice(12, 14)}`; // xx.xxx.xxx/xxxx-xx
    
    };

    public formatCellphone = (cellphone: string) => {
        cellphone = cellphone.replace(/\D/g, '');

        if (cellphone.length <= 2) {
            return `(${cellphone}`;
        }
        if (cellphone.length <= 7) {
            return `(${cellphone.slice(0, 2)}) ${cellphone.slice(2)}`;
        }
        if (cellphone.length <= 10) {
            return `(${cellphone.slice(0, 2)}) ${cellphone.slice(2, 7)}-${cellphone.slice(7)}`;
        }
        return `(${cellphone.slice(0, 2)}) ${cellphone.slice(2, 7)}-${cellphone.slice(7, 11)}`;
    };

    public formatCEP = (CEP: string) => {
        CEP = CEP.replace(/\D/g, '');
        if (CEP.length >= 6) {
            return `${CEP.slice(0, 5)}-${CEP.slice(5, 8)}`;
        }
        return `${CEP}`;
    };
}

export default MaskUtils;