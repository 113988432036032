import ReactGA from "react-ga4";
import {hashCPF} from "../../utils/HashCPF";

// Initialize Google Analytics
export const initializeAnalytics = (): void => {
    const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID || "";
    ReactGA.initialize(measurementId);
};

// Set User ID
export const setUserId = (cpf: string): void => {
    const hashedCpf = hashCPF(cpf);
    ReactGA.set({user_id: hashedCpf});
};

// Track page views
export const trackPageView = (url: string): void => {
    ReactGA.send({hitType: "pageview", page: url, debug_mode: true});
};
