import React from 'react';
import './FloatingWhatsAppButton.css';
import {WhatsAppOutlined} from '@ant-design/icons';


const FloatingWhatsAppButton: React.FC = () => {
    const handleClick = () => {
        const phoneNumber = '5511935027692';
        const message = encodeURIComponent('Olá, gostaria de saber mais sobre a antecipação de aluguel!');
        const url = `https://wa.me/${phoneNumber}?text=${message}`;
        window.open(url, '_blank');
    };

    return (
        <button
            className="floating-whatsapp-button"
            onClick={handleClick}
            aria-label="Contact us on WhatsApp"
        >
            <WhatsAppOutlined className="floating-whatsapp-button__icon"/>
        </button>
    );
};

export default FloatingWhatsAppButton;
