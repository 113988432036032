import {AnticipationPaymentDetails} from '../models/AnticipationPaymentDetails';

export const calculatePaymentAmount = async (
    rentAmount: number
): Promise<AnticipationPaymentDetails[]> => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/anticipation/calculate-payment-amount?rent_amount=${rentAmount}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY as string,
                },
            }
        );

        if (!response.ok) {
            // Handle non-2xx HTTP responses
            const errorData = await response.json();
            throw new Error(
                errorData.message ||
                'Falha ao calcular a antecipação. Por favor, tente mais tarde.'
            );
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};
