import React from 'react';
import './PaymentDataInput.css';
import {Button, Form, Input, Select} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import MaskUtils from '../../global/utils/MaskUtils';
import {cnpj, cpf} from 'cpf-cnpj-validator';
import Logo from '../../assets/images/aludeLogo.png';

type FieldType = {
    tipoPix?: string;
    chavePix?: string;
};

const {Option} = Select;

const PaymentDataInput: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    let incoming = location.state;

    if (incoming["tipoPix"] == null) {
        incoming = {...incoming, tipoPix: "CPF / CNPJ"};
    }

    const maskUtils = new MaskUtils();

    const onFinish = (values: FieldType) => {
        const state = {...incoming, ...values};
        navigate("/summary", {state});
    };

    return (
        <div className="payment-data">
            <img src={Logo} alt="Logo" className="payment-data__logo"/>
            <h1 className="payment-data__title">Insira seus dados bancários para pagamento</h1>
            <div className="payment-data__body">
                <Form
                    form={form}
                    name="paymentDataForm"
                    layout="vertical"
                    className="payment-data__form"
                    initialValues={incoming}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item<FieldType>
                        label="Tipo de chave Pix"
                        name="tipoPix"
                    >
                        <Select
                            style={{width: "100%"}}
                            onChange={() => form.setFieldsValue({chavePix: ''})}
                        >
                            <Option value="CPF / CNPJ">CPF / CNPJ</Option>
                            <Option value="Celular">Celular</Option>
                            <Option value="Email">Email</Option>
                            <Option value="Aleatória">Aleatória</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item<FieldType>
                        label="Chave Pix"
                        name="chavePix"
                        rules={[
                            {
                                validator: (_, value) => {
                                    const tipoPix = form.getFieldValue("tipoPix");
                                    if (tipoPix === 'CPF / CNPJ') {
                                        if (cpf.isValid(value) || cnpj.isValid(value)) {
                                            return Promise.resolve();
                                        }
                                        const errorMsg = value.replace(/\D/g, '').length <= 11 ? 'CPF inválido' : 'CNPJ inválido';
                                        return Promise.reject(new Error(errorMsg));
                                    } else if (tipoPix === 'Celular') {
                                        const phoneRegex = /^\(\d{2}\) \d{5}-\d{4}$/;
                                        if (phoneRegex.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Celular Inválido'));
                                    } else if (tipoPix === 'Email') {
                                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                        if (emailRegex.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Email inválido'));
                                    } else if (tipoPix === 'Aleatória') {
                                        const randomKeyRegex = /^[a-zA-Z0-9]{32}$/;
                                        if (randomKeyRegex.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Chave aleatória inválida'));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Input
                            onChange={(e) => {
                                const tipoPix = form.getFieldValue("tipoPix");
                                const value = e.target.value;
                                if (value) {
                                    if (tipoPix === 'CPF / CNPJ') {
                                        const chavePix = maskUtils.formatCNPJandCPF(value);
                                        form.setFieldsValue({chavePix});
                                    } else if (tipoPix === 'Celular') {
                                        const chavePix = maskUtils.formatCellphone(value);
                                        form.setFieldsValue({chavePix});
                                    }
                                }
                            }}
                        />
                    </Form.Item>
                </Form>
            </div>
            <footer className="payment-data__footer">
                <p className="payment-data__step-label">Passo 4 de 5</p>
                <div className="payment-data__footer-buttons">
                    <Button
                        className="payment-data__button"
                        type="default"
                        onClick={() => navigate('/user-input', {state: incoming})}
                    >
                        Voltar
                    </Button>
                    <Button
                        className="payment-data__button"
                        type="primary"
                        onClick={() => form.submit()}
                    >
                        Continuar
                    </Button>
                </div>
            </footer>
        </div>
    );
};

export default PaymentDataInput;
