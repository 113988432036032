import React from 'react';
import './DataSummary.css';
import {Button, Form, message} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import Logo from '../../assets/images/aludeLogo.png';
import {sendAnticipationData} from '../../global/apis/Anticipation';

type FieldType = {
    address?: string;
};

const DataSummary: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const incoming = location.state;

    const [form] = Form.useForm();

    const onFinish = async (values: FieldType) => {
        try {
            await sendAnticipationData(incoming);

            navigate('/success');
        } catch (error) {
            message.error('Tivemos um erro ao processar sua solicitação. Por favor, tente mais tarde.');
        }
    };

    return (
        <div className="data-summary">
            <img src={Logo} alt="Logo" className="data-summary__logo"/>
            <h1 className="data-summary__title">Revise todas as informações</h1>
            <div className="data-summary__body">
                <Form
                    form={form}
                    name="dataSummaryForm"
                    layout="vertical"
                    className="data-summary__form"
                    initialValues={incoming}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <div className="data-summary__content">
                        <div className="data-summary__line">
                            <p>Valor a ser antecipado</p>
                            <p>{incoming["valorTotal"]}</p>
                        </div>
                        <div className="data-summary__line">
                            <p>Valor a ser recebido</p>
                            <p style={{fontWeight: 'bold'}}>{incoming["valorPresente"]}</p>
                        </div>
                        <hr/>
                        <div className="data-summary__line">
                            <p>CPF</p>
                            <p>{incoming["cpf"]}</p>
                        </div>
                        <div className="data-summary__line">
                            <p>Tipo de chave Pix</p>
                            <p>{incoming["tipoPix"]}</p>
                        </div>
                        <div className="data-summary__line">
                            <p>Chave Pix</p>
                            <p>{incoming["chavePix"]}</p>
                        </div>
                    </div>
                </Form>
            </div>
            <footer className="data-summary__footer">
                <p className="data-summary__step-label">Passo 5 de 5</p>
                <div className="data-summary__footer-buttons">
                    <Button
                        className="data-summary__button"
                        type="default"
                        onClick={() => navigate('/payment', {state: incoming})}
                    >
                        Voltar
                    </Button>
                    <Button
                        className="data-summary__button"
                        type="primary"
                        onClick={() => form.submit()}
                    >
                        Continuar
                    </Button>
                </div>
            </footer>
        </div>
    )
        ;
};

export default DataSummary;
