export interface IncomingData {
    [key: string]: any;
}

export const sendAnticipationData = async (incoming: IncomingData): Promise<any> => {
    // Mapping for pix_key_type
    const pixKeyTypeMapping: { [key: string]: string } = {
        'CPF / CNPJ': 'document',
        'Celular': 'phone',
        'Email': 'email',
        'Aleatória': 'random',
    };

    // Build the data object for the API call
    const anticipationData = {
        user: {
            cpf: incoming['cpf'].replace(/[^\d]/g, ''),
            full_name: incoming['fullName'],
            email: incoming['email'],
            ddd: incoming['ddd'],
            phone: incoming['celNumber'].replace(/[^\d]/g, '').slice(-9),
            accept_terms: incoming['acceptTerms'],
        },
        address: {
            street: incoming['logradouro'],
            number: incoming['addressNumber'],
            complement: incoming['complement'] || "",
            neighborhood: incoming['bairro'],
            city: incoming['localidade'],
            state: incoming['uf'],
            zip_code: incoming['cep'].replace(/[^\d]/g, ''),
        },
        payment_method: {
            pix_key_type: pixKeyTypeMapping[incoming['tipoPix']] || incoming['tipoPix'],
            pix_key: incoming['chavePix'].replace(/[^\d]/g, ''),
        },
        contract: {
            rent_amount: incoming['rent'].replace(/\./g, '').replace(',', '.'),
            anticipation_months: incoming['mes'],
            anticipation_amount: incoming['valorTotal'].replace(/\./g, '').replace(',', '.').replace('R$', ''),
            advance_total_payment: incoming['valorPresente'].replace(/\./g, '').replace(',', '.').replace('R$', ''),
        },
    };

    // Make the API call
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/anticipation/data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_API_KEY as string,
            },
            body: JSON.stringify(anticipationData),
        });

        if (!response.ok) {
            // Handle non-2xx HTTP responses
            const errorData = await response.json();
            throw new Error(errorData.message || 'Falha ao solicitar a antecipação. Por favor, tente mais tarde.');
        }

        // Parse the JSON response
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};
