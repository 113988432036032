import React, {useEffect, useState} from 'react';
import './UserDataInput.css';
import {Button, Form, Input, Col, Row, Select, Skeleton} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import MaskUtils from '../../global/utils/MaskUtils';
import Logo from '../../assets/images/aludeLogo.png';

interface Address {
    logradouro: string;
    bairro: string;
    localidade: string;
    estado: string;
}

type FieldType = {
    fullName?: string;
    email?: string;
    celNumber?: string;
    addressNumber?: string;
    complement?: string;
    cep?: string;
    logradouro?: string;
    bairro?: string;
    localidade?: string;
    estado?: string;
    prefix?: string;
};

const {Option} = Select;

const UserDataInput: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const incoming = location.state || {};
    const maskUtils = new MaskUtils();
    const [loading, setLoading] = useState(false);
    const [addressString, setAddressString] = useState('');
    const [saveAddress, setSaveAddress] = useState<Address | null>(null);

    const buildAddress = (address: Address) => {
        const addressStr = `${address.logradouro}, ${address.bairro}, ${address.localidade}, ${address.estado}`;
        setAddressString(addressStr);
    };

    useEffect(() => {
        if (incoming.logradouro) {
            const address: Address = {
                logradouro: incoming.logradouro,
                bairro: incoming.bairro,
                localidade: incoming.localidade,
                estado: incoming.estado,
            };
            buildAddress(address);
        }
    }, [incoming]);

    const onFinish = (values: FieldType) => {
        const state = {...incoming, ...values, ...saveAddress};
        navigate('/payment', {state});
    };

    const [form] = Form.useForm();

    const getAddressByCEP = async (cep: string): Promise<any> => {
        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
        if (!response.ok) {
            throw new Error('Error fetching address');
        }
        return await response.json();
    };

    return (
        <div className="user-data">
            <img src={Logo} alt="Logo" className="user-data__logo"/>
            <h1 className="user-data__title">Confirme informações do aluguel</h1>
            <p className="user-data__subtitle">
                Insira seus dados pessoais e da propriedade que deseja antecipar o aluguel.
            </p>
            <div className="user-data__body">
                <Form
                    form={form}
                    name="userDataForm"
                    layout="vertical"
                    className="user-data__form"
                    initialValues={{...incoming, prefix: '+55'}}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item<FieldType>
                        label="Nome Completo"
                        name="fullName"
                        rules={[
                            {
                                required: true,
                                message: 'Nome inválido',
                                pattern: /^[A-Za-zÀ-ÿ]+(?: [A-Za-zÀ-ÿ]+)*$/,
                            },
                        ]}
                    >
                        <Input placeholder="Pedro Henrique da Silva Soares"/>
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item<FieldType>
                                label="E-mail"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'E-mail inválido',
                                        type: 'email',
                                    },
                                ]}
                            >
                                <Input placeholder="email@example.com"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item<FieldType>
                                label="Celular"
                                name="celNumber"
                                rules={[{required: true, message: 'Celular inválido'}]}
                            >
                                <Input
                                    addonBefore={
                                        <Form.Item name="prefix" noStyle initialValue="+55">
                                            <Select style={{width: 70}}>
                                                <Option value="+55">+55</Option>
                                            </Select>
                                        </Form.Item>
                                    }
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value) {
                                            const celNumber = maskUtils.formatCellphone(value);
                                            form.setFieldsValue({celNumber});
                                        } else {
                                            form.setFieldsValue({celNumber: ''});
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item<FieldType>
                        label="CEP"
                        name="cep"
                        rules={[{required: true, message: 'CEP Inválido'}]}
                    >
                        <Input
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value) {
                                    const cep = maskUtils.formatCEP(value);
                                    form.setFieldsValue({cep});

                                    if (cep.length === 9) {
                                        setLoading(true);
                                        const req = cep.replace(/\D/g, '');
                                        getAddressByCEP(req)
                                            .then((address) => {
                                                if (address.erro) {
                                                    throw new Error('Endereço não encontrado ou CEP inválido');
                                                }
                                                setSaveAddress(address);
                                                buildAddress(address);
                                                setLoading(false);
                                            })
                                            .catch((error) => {
                                                setLoading(false);
                                            });
                                    }
                                } else {
                                    form.setFieldsValue({cep: ''});
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Endereço">
                        <Skeleton active loading={loading} paragraph={{rows: 1, width: '100%'}} title={false}>
                            <Input
                                value={addressString}
                                readOnly
                                className="user-data__address-input"
                            />
                        </Skeleton>
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item<FieldType>
                                label="Número"
                                name="addressNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Número inválido',
                                        pattern: /^[0-9]+$/,
                                    },
                                ]}
                            >
                                <Input maxLength={6}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item<FieldType> label="Complemento" name="complement">
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <footer className="user-data__footer">
                <p className="user-data__step-label">Passo 3 de 5</p>
                <div className="user-data__footer-buttons">
                    <Button
                        className="user-data__button"
                        type="default"
                        onClick={() => navigate('/advancement', {state: incoming})}
                    >
                        Voltar
                    </Button>
                    <Button
                        className="user-data__button"
                        type="primary"
                        onClick={() => form.submit()}
                    >
                        Continuar
                    </Button>
                </div>
            </footer>
        </div>
    );
};

export default UserDataInput;
