// src/routes/AppRoutes.tsx
import React from 'react';
import {Routes, Route} from 'react-router-dom';
import LandingPage from '../pages/LandingPage/LandingPage';
import UserDataInput from '../pages/UserDataInput/UserDataInput';
import RentAdvancementInput from '../pages/RentAdvancementInput/RentAdvancementInput';
import PaymentDataInput from '../pages/PaymentDataInput/PaymentDataInput';
import DataSummary from '../pages/DataSummary/DataSummary';
import SuccessPage from '../pages/SuccessPage/SuccessPage';


const AppRoutes: React.FC = () => {

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<LandingPage/>}/>
                <Route path="/advancement" element={<RentAdvancementInput/>}/>
                <Route path="/user-input" element={<UserDataInput/>}/>
                <Route path="/payment" element={<PaymentDataInput/>}/>
                <Route path="/summary" element={<DataSummary/>}/>
                <Route path="/success" element={<SuccessPage/>}/>
            </Routes>
        </div>
    );
};

export default AppRoutes;
