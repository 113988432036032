import React from 'react';
import './LandingPage.css';
import {Button, Checkbox, Form, Input} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import NumberUtils from '../../global/utils/NumberUtils';
import MaskUtils from '../../global/utils/MaskUtils';
import {cpf as cpfValidator} from 'cpf-cnpj-validator';
import Logo from '../../assets/images/aludeLogo.png';
import {setUserId} from '../../global/components/Analytics/Analytics';

type FieldType = {
    cpf?: string;
    acceptTerms?: boolean;
    rent?: string;
};

const LandingPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const incoming = location.state;
    const maskUtils = new MaskUtils();
    const numberUtils = new NumberUtils();

    const [form] = Form.useForm();

    const onFinish = (values: FieldType) => {
        const state = {...incoming, ...values};

        if (values.cpf) {
            setUserId(values.cpf);
        }

        navigate('/advancement', {state});
    };

    const persistData = incoming || {};

    return (
        <div className="landing-page">
            <img src={Logo} alt="Logo" className="landing-page__logo"/>
            <h1 className="landing-page__title">Confirme seus dados</h1>
            <div className="landing-page__body">
                <Form
                    form={form}
                    name="landingForm"
                    layout="vertical"
                    className="landing-page__form"
                    initialValues={persistData}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        label="CPF"
                        name="cpf"
                        getValueFromEvent={(e) => {
                            const value = e.target.value;
                            return value ? maskUtils.formatCPF(value) : '';
                        }}
                        rules={[
                            {
                                required: true,
                                validator: (_, value) =>
                                    cpfValidator.isValid(value)
                                        ? Promise.resolve()
                                        : Promise.reject(new Error('CPF Inválido')),
                            },
                        ]}
                    >
                        <Input placeholder="xxx.xxx.xxx-xx"/>
                    </Form.Item>

                    <Form.Item
                        label="Valor do aluguel que você recebe em sua conta (líquido)"
                        name="rent"
                        getValueFromEvent={(e) => {
                            const value = e.target.value.replace(/\D/g, '');
                            return value ? numberUtils.formatInputMonetary(value) : '';
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'Valor do aluguel inválido',
                                pattern: /^(?=.*\d)((\d{1,3}(\.\d{3})*)|(\d+))(,\d+)?$/,
                            },
                        ]}
                    >
                        <Input maxLength={12} placeholder="R$"/>
                    </Form.Item>

                    <Form.Item
                        name="acceptTerms"
                        valuePropName="checked"
                        className="landing-page__checkbox-item"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value
                                        ? Promise.resolve()
                                        : Promise.reject(new Error('Aceite os termos para prosseguir')),
                            },
                        ]}
                    >
                        <Checkbox>
                            Eu autorizo o uso de dados fornecidos para imobiliária e sistemas parceiros para
                            oferta de antecipações.
                        </Checkbox>
                    </Form.Item>
                </Form>
            </div>
            <footer className="landing-page__footer">
                <p className="landing-page__step-label">Passo 1 de 5</p>
                <Button
                    className="landing-page__submit-button"
                    type="primary"
                    onClick={() => form.submit()}
                >
                    Continuar
                </Button>
            </footer>
        </div>
    );
};

export default LandingPage;
