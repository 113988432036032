import React, {useEffect, useState} from 'react';
import './RentAdvancementInput.css';
import {Button, Form, Radio, Space, message} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import Logo from '../../assets/images/aludeLogo.png';
import {calculatePaymentAmount} from '../../global/apis/Calculate';
import {AnticipationPaymentDetails} from '../../global/models/AnticipationPaymentDetails';

type FieldType = {
    anticipationOption?: number;
};

interface PaymentDetail {
    mes: number;
    unidade: string;
    valorTotal: string;
    valorPresente: string;
}

const RentAdvancementInput: React.FC = () => {
    const location = useLocation();
    const incoming = location.state;
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [paymentDetails, setPaymentDetails] = useState<PaymentDetail[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchPaymentDetails = async () => {
            setLoading(true);
            try {
                // Parse the rent amount from the incoming data
                const rentAmount = parseFloat(
                    incoming['rent'].replace(/\./g, '').replace(',', '.')
                );

                // Fetch payment details from the API
                const data: AnticipationPaymentDetails[] = await calculatePaymentAmount(
                    rentAmount
                );

                // Define a formatter for Brazilian currency
                const currencyFormatter = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                });

                // Transform the data to match the format used in the component
                const transformedData: PaymentDetail[] = data.map((item) => ({
                    mes: item.contract_months,
                    unidade: item.contract_months > 1 ? 'aluguéis' : 'aluguel',
                    valorTotal: currencyFormatter.format(
                        rentAmount * item.contract_months
                    ),
                    valorPresente: currencyFormatter.format(item.advance_amount),
                }));

                setPaymentDetails(transformedData);
            } catch (error) {
                message.error(
                    'Erro ao calcular o valor do pagamento. Por favor, tente mais tarde.'
                );
            } finally {
                setLoading(false);
            }
        };

        fetchPaymentDetails();
    }, [incoming]);

    const onFinish = (values: FieldType) => {
        const selected = paymentDetails.find(
            (item) => item.mes === values['anticipationOption']
        );
        const state = {
            ...incoming,
            ...selected,
            anticipationOption: values['anticipationOption'],
        };
        navigate('/user-input', {state});
    };

    return (
        <div className="rent-advancement">
            <img src={Logo} alt="Logo" className="rent-advancement__logo"/>
            <h1 className="rent-advancement__title">
                Quantos aluguéis você quer antecipar?
            </h1>
            <p className="rent-advancement__subtitle">
                Simule quantos aluguéis você quer antecipar.
            </p>
            <div className="rent-advancement__body">
                {loading ? (
                    <p>Carregando...</p>
                ) : (
                    <Form
                        form={form}
                        name="rentAdvancementForm"
                        layout="vertical"
                        className="rent-advancement__form"
                        initialValues={incoming}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="anticipationOption"
                            rules={[{required: true, message: 'Por favor, selecione uma opção'}]}
                        >
                            <Radio.Group className="rent-advancement__radio-group">
                                <Space direction="vertical" className="rent-advancement__radio-space">
                                    {paymentDetails.map((e) => (
                                        <Radio
                                            value={e.mes}
                                            key={e.mes}
                                            className="rent-advancement__radio-item"
                                        >
                                            <div className="rent-advancement__radio-text">
                                                <p>
                                                    {e.mes} {e.unidade}
                                                </p>
                                                <p style={{fontWeight: 'bold'}}>{e.valorPresente}</p>
                                            </div>
                                        </Radio>
                                    ))}
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                )}
                <div className="rent-advancement__faq">
                    <p>
                        Caso tenha alguma dúvida, consulte nossa <a href={process.env.REACT_APP_FAQ_URL} target="_blank"
                                                                    rel="noopener noreferrer">FAQ</a>
                    </p>
                </div>
            </div>
            <footer className="rent-advancement__footer">
                <p className="rent-advancement__step-label">Passo 2 de 5</p>
                <div className="rent-advancement__footer-buttons">
                    <Button
                        className="rent-advancement__button"
                        type="default"
                        onClick={() => navigate('/', {state: incoming})}
                    >
                        Voltar
                    </Button>
                    <Button
                        className="rent-advancement__button"
                        type="primary"
                        onClick={() => form.submit()}
                        disabled={loading}
                    >
                        Continuar
                    </Button>
                </div>
            </footer>
        </div>
    );
};

export default RentAdvancementInput;
