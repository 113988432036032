import React, {useEffect} from "react";
import {useLocation} from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import "./App.css";
import {initializeAnalytics, trackPageView} from "./global/components/Analytics/Analytics";
import FloatingWhatsAppButton from './global/components/FloatingWhatsAppButton/FloatingWhatsAppButton';

const App: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    // Initialize Google Analytics when the app loads
    initializeAnalytics();
  }, []);

  useEffect(() => {
    // Track page view on route change
    trackPageView(location.pathname);
  }, [location]);

  return (
    <>
      <AppRoutes/>
      <FloatingWhatsAppButton/>
    </>
  );
};

export default App;
