class NumberUtils{
    public formatInputMonetary(num: string) {
        const decimalPosition = num.length - 2;
        const integerPart = num.slice(0, decimalPosition);
        const decimalPart = num.slice(decimalPosition);
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        const formattedNum = formattedIntegerPart + ',' + decimalPart;
    
        return formattedNum;
    }
}

export default NumberUtils;