import React from 'react';
import './SuccessPage.css';
import {Result} from 'antd';

// Import the logo
import Logo from '../../assets/images/aludeLogo.png';

const SuccessPage: React.FC = () => {
    return (

        <div className="success-page">
            <img src={Logo} alt="Logo" className="success-page__logo"/>
            <div className="success-page__body">
                <Result
                    status="success"
                    title="Recebemos o seu pedido!"
                    subTitle="Estamos revisando seus dados. Entraremos em contato via WhatsApp com sua oferta e contrato."
                    className="success-page__result"
                />
            </div>
        </div>
    );
};

export default SuccessPage;
